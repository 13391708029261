// __________________________________
//  Map Configuration               |
// ----------------------------------
//        \   ^__^
//         \  (oo)\_______
//            (__)\       )\/\
//                ||----w |
//                ||     ||
//
//
// style        gl style json, could be your tiles or mapbox or whatever. I'd recommend
//              using the great osm-liberty style from https://github.com/lukasmartinelli/osm-liberty,
//              which is included. Just change style to "./style/osm-liberty.json"
//
//
// zoomEmbed    default zoom level for embed map
// zoom         default zoom level for dashboard map
// centerEmbed  center coordinates for embed map
// center       center coordinates for dashboard map
// maxBounds    max bounds so your users don't go zipping around the planet. format LL, UR
// minZoom      minimum zoom level
// preserveDrawingBuffer    this is to make sure Firefox can print the map
// neighborhoodsSelectedBefore      the layer id in your map style to drop the selected line before, usually the first labels
// neighborhoodsBefore      the layer in your map style to drop the polygon fills before, usually buildings


let mapConfig = {
    style: './style/osm-liberty.json',
    
   
    zoomEmbed: 7.5,
    zoom: 7.3,
    /*centerEmbed: [-80.815,35.31],
    center: [-80.84, 35.26],
    maxBounds: [[-82.641, 34.115],[-79.008, 36.762]],
    */
   
    centerEmbed: [-90.209,38.62],
    center: [-90.209, 38.62],
    /* maxBounds: [[-95.045, 42.628],[-87.572, 42.684]], */ 

    minZoom: 8,
    preserveDrawingBuffer: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,  // fix for Firefox print
    /*neighborhoodsSelectedBefore: 'water_label',
    neighborhoodsBefore: 'building'
    */
};

module.exports = mapConfig;

/*
    centerEmbed: [-90.209,38.62],
    center: [-90.209, 38.62],
    maxBounds: [[-91.045, 38.628],[-89.572, 38.684]],

*/