// __________________________________
//  Site Configuration              |
// ----------------------------------
//        \   ^__^
//         \  (oo)\_______
//            (__)\       )\/\
//                ||----w |
//                ||     ||
//
//  Pretty self-explanatory, except for these:
//
//  qolembedURL     if you have a qol embed install, put the location here, otherwise set to null
//  qolreportURL    if you have a qol report install, put the location here, otherwise set to null
//  qoldashboardURL     if you have a qol dashboard install, put the location here, otherwise set to null
//  contactForm     location of your contact form if you have one for the dashboard, otherwise null
// POST arguments to the contact page will be:
//     email : sender email,
//     url: the current window location,
//     agent: users's browser info,
//     subject: "Whatever",
//     to: email address(s) to send the feedback to,
//     message: "I hate your website or whatever"
//  whatsnew        an array of recently updated metric id's to put in a what's new box, like ["2", "12"].
//                  set to null if you've got none
//  contacts "tobin.bradley@gmail.com,qualityoflife@charlottenc.gov",
//

let siteConfig = {
  neighborhoodDescriptor: 'Neighborhood',
  neighborhoodDefinition:
    'Neighborhood within the City of St. Louis or Municipalities in the St. Louis area.',
  gaKey: 'UA-48797957-1',
  keywords:
    'GIS, St. Louis,parks,libraries,schools,environment,impervious surface,floodplain,zoning,assessment,tax,photos,government',
  author: 'John Cruz',
  title: 'Data Gateway',
  jurisdiction: 'St. Louis Metro',
  description:
    'Exploring neighborhood character, economics, education, engagement, environment, health, housing, safety and transportation.',
  qolembedURL: 'https://mcmap.org/qol-embed/',
  qolreportURL: 'https://mcmap.org/qol-report/',
  qoldashboardURL: 'https://mcmap.org/qol/',
  contactForm: 'https://mcmap.org/utilities/feedback.php',
  contacts: 'johnc@risestl.org',
  whatsnew: [
    '1',
    '2',
  ],
  links: [
    {
      href: 'http://www.risestl.org',
      text: 'About'
    },
    {
      href: 'downloads/qol-data.zip',
      text: 'Download Data'
    },
    {
      href: 'http://www.risestl.org/',
      text: 'Rise Community Development'
    },
    {
      href: 'http://www.risestl.org/contact',
      text: 'Contact Us'
    }
  ]
};

module.exports = siteConfig;
